export function RunNovaCurrencyConversionLogic() {
    if (IsNovaCurrencyCompatible()) {
        DebouncedFormatCurrencyWithAmount(null);
        SessionStorageTrigger();
    }
}

export function IsNovaCurrencyCompatible() {
    return (
        window.baCurr &&
        window.baCurr.config &&
        window.baCurr.Currency &&
        window.baCurr.Currency.rates &&
        Object.keys(window.baCurr.Currency.rates).length > 0 &&
        ($(location).attr('href').indexOf("cpc-qa") > 0  || $(location).attr('href').indexOf("unikportrait") > 0 || $(location).attr('href').indexOf("awspainting") > 0 || $(location).attr('href').indexOf("stickerprint") > 0)
    );
}

function Debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
    };
}

export function HasPriceTickerFormattedWithNovaCurrency(amount, element) {
    if (IsNovaCurrencyCompatible()) {
        const config = window.baCurr.config;
        const converter = CurrencyConverter(window.baCurr.Currency.rates);
        const targetCurrency = GetTargetCurrency(null);
        const ticker = bacurr$(element);
        if (ticker.length) {
            const originalValue = ticker.attr("data-origMoney");
            console.log("originalValue", amount);
            ticker.data("bacurr-origMoneyVal", originalValue);
            let result = converter.convert(parseFloat(amount), config.user_curr, targetCurrency, false);
            ticker.html(result);
            return true;
        }
    }
    return false;
}

function FormatPriceTickerCurrencyWithAmount(value) {
    const config = window.baCurr.config;
    const converter = CurrencyConverter(window.baCurr.Currency.rates);
    const targetCurrency = GetTargetCurrency(value);
    $("span.money.cpc-priceTicker").each(function () {
        const convertTicker = () => {
            let priceTickerId= $(this).attr('id');
            const ticker = bacurr$('#'+priceTickerId);
            if (ticker.length) {
                const originalValue = ticker.attr("data-origMoney");
                console.log("originalValue", originalValue);
                ticker.data("bacurr-origMoneyVal", originalValue);
                const result = converter.convert(parseFloat(originalValue), config.user_curr, targetCurrency, false);
                ticker.html(result);
            }
        };
        convertTicker();
    });
}

export function GetAmountAfterConversion(amountToConvert, userCurrency, targetCurrencyValue) {
    const converter = CurrencyConverter(window.baCurr.Currency.rates);
    const targetCurrency = GetTargetCurrency(targetCurrencyValue);
    const result = converter.getConvertedAmount(parseFloat(amountToConvert), userCurrency, targetCurrency);
    return result;
}


// Create a debounced version of FormatCurrencyWithAmount with a 200ms delay
const DebouncedFormatCurrencyWithAmount = Debounce(FormatPriceTickerCurrencyWithAmount, 200);

export const GetTargetCurrency = (inputValue) => {
    const sessionValue = sessionStorage.getItem('bacurr_user_cur');
    try {
        return inputValue ? JSON.parse(inputValue) : JSON.parse(sessionValue ?? null);
    } catch {
        return JSON.parse(sessionValue ?? null);
    }
};

function SessionStorageTrigger() {
    const originalSet = sessionStorage.setItem;
    // Override sessionStorage setItem
    sessionStorage.setItem = function (key, value) {
        if (key === 'bacurr_user_cur') {
            DebouncedFormatCurrencyWithAmount(value); // Call your custom function
        }
        originalSet.apply(this, arguments); // Preserve original behavior
    };

}


// Custom currency converter that only uses rates from baCurr
export const CurrencyConverter = (rates) => {
    // Define currency formats for displaying amounts
    const formats = {
        USD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} USD"
        },
        EUR: {
            money_format: "&euro;{{amount}}",
            money_with_currency_format: "&euro;{{amount}} EUR"
        },
        GBP: {
            money_format: "&pound;{{amount}}",
            money_with_currency_format: "&pound;{{amount}} GBP"
        },
        CAD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} CAD"
        },
        ALL: {
            money_format: "Lek {{amount}}",
            money_with_currency_format: "Lek {{amount}} ALL"
        },
        DZD: {
            money_format: "DA {{amount}}",
            money_with_currency_format: "DA {{amount}} DZD"
        },
        AOA: {
            money_format: "Kz{{amount}}",
            money_with_currency_format: "Kz{{amount}} AOA"
        },
        ARS: {
            money_format: "${{amount_with_comma_separator}}",
            money_with_currency_format: "${{amount_with_comma_separator}} ARS"
        },
        AMD: {
            money_format: "{{amount}} AMD",
            money_with_currency_format: "{{amount}} AMD"
        },
        AWG: {
            money_format: "Afl{{amount}}",
            money_with_currency_format: "Afl{{amount}} AWG"
        },
        AUD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} AUD"
        },
        BBD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} Bds"
        },
        AZN: {
            money_format: "m.{{amount}}",
            money_with_currency_format: "m.{{amount}} AZN"
        },
        BDT: {
            money_format: "Tk {{amount}}",
            money_with_currency_format: "Tk {{amount}} BDT"
        },
        BSD: {
            money_format: "BS${{amount}}",
            money_with_currency_format: "BS${{amount}} BSD"
        },
        BHD: {
            money_format: "{{amount}}0 BD",
            money_with_currency_format: "{{amount}}0 BHD"
        },
        BYR: {
            money_format: "Br {{amount}}",
            money_with_currency_format: "Br {{amount}} BYR"
        },
        BZD: {
            money_format: "BZ${{amount}}",
            money_with_currency_format: "BZ${{amount}} BZD"
        },
        BTN: {
            money_format: "Nu {{amount}}",
            money_with_currency_format: "Nu {{amount}} BTN"
        },
        BAM: {
            money_format: "KM {{amount_with_comma_separator}}",
            money_with_currency_format: "KM {{amount_with_comma_separator}} BAM"
        },
        BRL: {
            money_format: "R$ {{amount_with_comma_separator}}",
            money_with_currency_format: "R$ {{amount_with_comma_separator}} BRL"
        },
        BOB: {
            money_format: "Bs{{amount_with_comma_separator}}",
            money_with_currency_format: "Bs{{amount_with_comma_separator}} BOB"
        },
        BWP: {
            money_format: "P{{amount}}",
            money_with_currency_format: "P{{amount}} BWP"
        },
        BND: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} BND"
        },
        BGN: {
            money_format: "{{amount}} BGN",
            money_with_currency_format: "{{amount}} BGN"
        },
        MMK: {
            money_format: "K{{amount}}",
            money_with_currency_format: "K{{amount}} MMK"
        },
        KHR: {
            money_format: "KHR{{amount}}",
            money_with_currency_format: "KHR{{amount}}"
        },
        KYD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} KYD"
        },
        XAF: {
            money_format: "FCFA{{amount}}",
            money_with_currency_format: "FCFA{{amount}} XAF"
        },
        CLP: {
            money_format: "${{amount_no_decimals}}",
            money_with_currency_format: "${{amount_no_decimals}} CLP"
        },
        CNY: {
            money_format: "&#165;{{amount}}",
            money_with_currency_format: "&#165;{{amount}} CNY"
        },
        COP: {
            money_format: "${{amount_with_comma_separator}}",
            money_with_currency_format: "${{amount_with_comma_separator}} COP"
        },
        CRC: {
            money_format: "&#8353; {{amount_with_comma_separator}}",
            money_with_currency_format: "&#8353; {{amount_with_comma_separator}} CRC"
        },
        HRK: {
            money_format: "{{amount_with_comma_separator}} kn",
            money_with_currency_format: "{{amount_with_comma_separator}} kn HRK"
        },
        CZK: {
            money_format: "{{amount_with_comma_separator}} K&#269;",
            money_with_currency_format: "{{amount_with_comma_separator}} K&#269;"
        },
        DKK: {
            money_format: "{{amount_with_comma_separator}}",
            money_with_currency_format: "kr.{{amount_with_comma_separator}}"
        },
        DOP: {
            money_format: "RD$ {{amount}}",
            money_with_currency_format: "RD$ {{amount}}"
        },
        XCD: {
            money_format: "${{amount}}",
            money_with_currency_format: "EC${{amount}}"
        },
        EGP: {
            money_format: "LE {{amount}}",
            money_with_currency_format: "LE {{amount}} EGP"
        },
        ETB: {
            money_format: "Br{{amount}}",
            money_with_currency_format: "Br{{amount}} ETB"
        },
        XPF: {
            money_format: "{{amount_no_decimals_with_comma_separator}} XPF",
            money_with_currency_format: "{{amount_no_decimals_with_comma_separator}} XPF"
        },
        FJD: {
            money_format: "${{amount}}",
            money_with_currency_format: "FJ${{amount}}"
        },
        GMD: {
            money_format: "D {{amount}}",
            money_with_currency_format: "D {{amount}} GMD"
        },
        GHS: {
            money_format: "GH&#8373;{{amount}}",
            money_with_currency_format: "GH&#8373;{{amount}}"
        },
        GTQ: {
            money_format: "Q{{amount}}",
            money_with_currency_format: "{{amount}} GTQ"
        },
        GYD: {
            money_format: "G${{amount}}",
            money_with_currency_format: "${{amount}} GYD"
        },
        GEL: {
            money_format: "{{amount}} GEL",
            money_with_currency_format: "{{amount}} GEL"
        },
        HNL: {
            money_format: "L {{amount}}",
            money_with_currency_format: "L {{amount}} HNL"
        },
        HKD: {
            money_format: "${{amount}}",
            money_with_currency_format: "HK${{amount}}"
        },
        HUF: {
            money_format: "{{amount_no_decimals_with_comma_separator}}",
            money_with_currency_format: "{{amount_no_decimals_with_comma_separator}} Ft"
        },
        ISK: {
            money_format: "{{amount_no_decimals}} kr",
            money_with_currency_format: "{{amount_no_decimals}} kr ISK"
        },
        INR: {
            money_format: "Rs. {{amount}}",
            money_with_currency_format: "Rs. {{amount}}"
        },
        IDR: {
            money_format: "{{amount_with_comma_separator}}",
            money_with_currency_format: "Rp {{amount_with_comma_separator}}"
        },
        ILS: {
            money_format: "{{amount}} NIS",
            money_with_currency_format: "{{amount}} NIS"
        },
        JMD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} JMD"
        },
        JPY: {
            money_format: "&#165;{{amount_no_decimals}}",
            money_with_currency_format: "&#165;{{amount_no_decimals}} JPY"
        },
        JEP: {
            money_format: "&pound;{{amount}}",
            money_with_currency_format: "&pound;{{amount}} JEP"
        },
        JOD: {
            money_format: "{{amount}}0 JD",
            money_with_currency_format: "{{amount}}0 JOD"
        },
        KZT: {
            money_format: "{{amount}} KZT",
            money_with_currency_format: "{{amount}} KZT"
        },
        KES: {
            money_format: "KSh{{amount}}",
            money_with_currency_format: "KSh{{amount}}"
        },
        KWD: {
            money_format: "{{amount}}0 KD",
            money_with_currency_format: "{{amount}}0 KWD"
        },
        KGS: {
            money_format: "KGS {{amount}}",
            money_with_currency_format: "KGS {{amount}}"
        },
        LVL: {
            money_format: "Ls {{amount}}",
            money_with_currency_format: "Ls {{amount}} LVL"
        },
        LBP: {
            money_format: "L&pound;{{amount}}",
            money_with_currency_format: "L&pound;{{amount}} LBP"
        },
        LTL: {
            money_format: "{{amount}} Lt",
            money_with_currency_format: "{{amount}} Lt"
        },
        MGA: {
            money_format: "Ar {{amount}}",
            money_with_currency_format: "Ar {{amount}} MGA"
        },
        MKD: {
            money_format: "\u0420\u0491\u0420\xB5\u0420\u0405 {{amount}}",
            money_with_currency_format: "\u0420\u0491\u0420\xB5\u0420\u0405 {{amount}} MKD"
        },
        MOP: {
            money_format: "MOP${{amount}}",
            money_with_currency_format: "MOP${{amount}}"
        },
        MVR: {
            money_format: "Rf{{amount}}",
            money_with_currency_format: "Rf{{amount}} MRf"
        },
        MXN: {
            money_format: "$ {{amount}}",
            money_with_currency_format: "$ {{amount}} MXN"
        },
        MYR: {
            money_format: "RM{{amount}} MYR",
            money_with_currency_format: "RM{{amount}} MYR"
        },
        MUR: {
            money_format: "Rs {{amount}}",
            money_with_currency_format: "Rs {{amount}} MUR"
        },
        MDL: {
            money_format: "{{amount}} MDL",
            money_with_currency_format: "{{amount}} MDL"
        },
        MAD: {
            money_format: "{{amount}} dh",
            money_with_currency_format: "Dh {{amount}} MAD"
        },
        MNT: {
            money_format: "{{amount_no_decimals}} &#8366",
            money_with_currency_format: "{{amount_no_decimals}} MNT"
        },
        MZN: {
            money_format: "{{amount}} Mt",
            money_with_currency_format: "Mt {{amount}} MZN"
        },
        NAD: {
            money_format: "N${{amount}}",
            money_with_currency_format: "N${{amount}} NAD"
        },
        NPR: {
            money_format: "Rs{{amount}}",
            money_with_currency_format: "Rs{{amount}} NPR"
        },
        ANG: {
            money_format: "&fnof;{{amount}}",
            money_with_currency_format: "{{amount}} NA&fnof;"
        },
        NZD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} NZD"
        },
        NIO: {
            money_format: "C${{amount}}",
            money_with_currency_format: "C${{amount}} NIO"
        },
        NGN: {
            money_format: "&#8358;{{amount}}",
            money_with_currency_format: "&#8358;{{amount}} NGN"
        },
        NOK: {
            money_format: "kr {{amount_with_comma_separator}}",
            money_with_currency_format: "kr {{amount_with_comma_separator}} NOK"
        },
        OMR: {
            money_format: "{{amount_with_comma_separator}} OMR",
            money_with_currency_format: "{{amount_with_comma_separator}} OMR"
        },
        PKR: {
            money_format: "Rs.{{amount}}",
            money_with_currency_format: "Rs.{{amount}} PKR"
        },
        PGK: {
            money_format: "K {{amount}}",
            money_with_currency_format: "K {{amount}} PGK"
        },
        PYG: {
            money_format: "Gs. {{amount_no_decimals_with_comma_separator}}",
            money_with_currency_format: "Gs. {{amount_no_decimals_with_comma_separator}} PYG"
        },
        PEN: {
            money_format: "S/. {{amount}}",
            money_with_currency_format: "S/. {{amount}} PEN"
        },
        PHP: {
            money_format: "&#8369;{{amount}}",
            money_with_currency_format: "&#8369;{{amount}} PHP"
        },
        PLN: {
            money_format: "{{amount_with_comma_separator}} zl",
            money_with_currency_format: "{{amount_with_comma_separator}} zl PLN"
        },
        QAR: {
            money_format: "QAR {{amount_with_comma_separator}}",
            money_with_currency_format: "QAR {{amount_with_comma_separator}}"
        },
        RON: {
            money_format: "{{amount_with_comma_separator}} lei",
            money_with_currency_format: "{{amount_with_comma_separator}} lei RON"
        },
        RUB: {
            money_format: "&#1088;&#1091;&#1073;{{amount_with_comma_separator}}",
            money_with_currency_format: "&#1088;&#1091;&#1073;{{amount_with_comma_separator}} RUB"
        },
        RWF: {
            money_format: "{{amount_no_decimals}} RF",
            money_with_currency_format: "{{amount_no_decimals}} RWF"
        },
        WST: {
            money_format: "WS$ {{amount}}",
            money_with_currency_format: "WS$ {{amount}} WST"
        },
        SAR: {
            money_format: "{{amount}} SR",
            money_with_currency_format: "{{amount}} SAR"
        },
        STD: {
            money_format: "Db {{amount}}",
            money_with_currency_format: "Db {{amount}} STD"
        },
        RSD: {
            money_format: "{{amount}} RSD",
            money_with_currency_format: "{{amount}} RSD"
        },
        SCR: {
            money_format: "Rs {{amount}}",
            money_with_currency_format: "Rs {{amount}} SCR"
        },
        SGD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} SGD"
        },
        SYP: {
            money_format: "S&pound;{{amount}}",
            money_with_currency_format: "S&pound;{{amount}} SYP"
        },
        ZAR: {
            money_format: "R {{amount}}",
            money_with_currency_format: "R {{amount}} ZAR"
        },
        KRW: {
            money_format: "&#8361;{{amount_no_decimals}}",
            money_with_currency_format: "&#8361;{{amount_no_decimals}} KRW"
        },
        LKR: {
            money_format: "Rs {{amount}}",
            money_with_currency_format: "Rs {{amount}} LKR"
        },
        SEK: {
            money_format: "{{amount_no_decimals}} kr",
            money_with_currency_format: "{{amount_no_decimals}} kr SEK"
        },
        CHF: {
            money_format: "SFr. {{amount}}",
            money_with_currency_format: "SFr. {{amount}} CHF"
        },
        TWD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} TWD"
        },
        THB: {
            money_format: "{{amount}} &#xe3f;",
            money_with_currency_format: "{{amount}} &#xe3f; THB"
        },
        TZS: {
            money_format: "{{amount}} TZS",
            money_with_currency_format: "{{amount}} TZS"
        },
        TTD: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}} TTD"
        },
        TND: {
            money_format: "{{amount}}",
            money_with_currency_format: "{{amount}} DT"
        },
        TRY: {
            money_format: "{{amount}}TL",
            money_with_currency_format: "{{amount}}TL"
        },
        UGX: {
            money_format: "Ush {{amount_no_decimals}}",
            money_with_currency_format: "Ush {{amount_no_decimals}} UGX"
        },
        UAH: {
            money_format: "\u0432\u201A\u0491{{amount}}",
            money_with_currency_format: "\u0432\u201A\u0491{{amount}} UAH"
        },
        AED: {
            money_format: "Dhs. {{amount}}",
            money_with_currency_format: "Dhs. {{amount}} AED"
        },
        UYU: {
            money_format: "${{amount_with_comma_separator}}",
            money_with_currency_format: "${{amount_with_comma_separator}} UYU"
        },
        VUV: {
            money_format: "${{amount}}",
            money_with_currency_format: "${{amount}}VT"
        },
        VEF: {
            money_format: "Bs. {{amount_with_comma_separator}}",
            money_with_currency_format: "Bs. {{amount_with_comma_separator}} VEF"
        },
        VND: {
            money_format: "{{amount_no_decimals_with_comma_separator}}&#8363;",
            money_with_currency_format: "{{amount_no_decimals_with_comma_separator}} VND"
        },
        XBT: {
            money_format: "{{amount_no_decimals}} BTC",
            money_with_currency_format: "{{amount_no_decimals}} BTC"
        },
        XOF: {
            money_format: "CFA{{amount}}",
            money_with_currency_format: "CFA{{amount}} XOF"
        },
        ZMW: {
            money_format: "K{{amount_no_decimals_with_comma_separator}}",
            money_with_currency_format: "ZMW{{amount_no_decimals_with_comma_separator}}"
        }
    };
    // Retrieve the appropriate format for a currency
    function getFormat(currency, withCurrencyCode = false) {
        const formatSettings = formats[currency] || {
            money_format: "{{amount}}",
            money_with_currency_format: "{{amount}} " + currency
        };
        return withCurrencyCode ? formatSettings.money_with_currency_format : formatSettings.money_format;
    }

    // Format the amount based on currency format settings
    function formatCurrency(amount, currency, withCurrencyCode = false) {
        // Get format template and determine decimal places
        const formatTemplate = getFormat(currency, withCurrencyCode);
        const decimals = ['KWD', 'JOD', 'OMR', 'BHD'].includes(currency) ? 3 : 2;

        // Format the amount (adding thousands separators and decimals)
        const formattedAmount = parseFloat(amount)
            .toFixed(decimals)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Replace placeholder in format template with the formatted amount
        return formatTemplate
            .replace('{{amount}}', formattedAmount)
            .replace('{{amount_no_decimals}}', parseInt(formattedAmount, 10));
    }

    // Convert currency from source to target, and format the result
    function getConvertedAmount(amount, fromCurrency, toCurrency) {
        // Check that conversion rates for both currencies exist
        if (!rates || !rates[fromCurrency] || !rates[toCurrency]) {
            throw new Error(`Currency rate not found for ${fromCurrency} or ${toCurrency}`);
        }

        // Calculate conversion rate and convert the amount
        const conversionRate = rates[fromCurrency] / rates[toCurrency];
        return amount * conversionRate;
    }

    // Convert currency from source to target, and format the result
    function convert(amount, fromCurrency, toCurrency, withCurrencyCode = false) {
        const amountAfterConversion = getConvertedAmount(amount, fromCurrency, toCurrency);

        // Format and return the converted amount
        return formatCurrency(amountAfterConversion, toCurrency, withCurrencyCode);
    }

    // Return an object exposing the methods
    return {
        getFormat,
        formatCurrency,
        convert,
        getConvertedAmount
    };
};